import React from 'react';

const DetailTitle = props => {
  return (
    <div className='detail-title'>
      <h2>{props.title}</h2>
    </div>
  );
};

export default DetailTitle;
