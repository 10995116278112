import React from 'react';

const Title = props => {
  return (
    <div className='title-wrap'>
      <h3>{props.title}</h3>
      <span>{props.descript}</span>
    </div>
  );
};

export default Title;
