import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgIpet01 from '../../assets/images/detail/ipet01.png';
import ImgIpet02 from '../../assets/images/detail/ipet02.png';
import ImgIpet03 from '../../assets/images/detail/ipet03.png';
import ImgIpet04 from '../../assets/images/detail/ipet04.png';

const IPetScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='iPet “愛寵GPS寵物追蹤器”' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>UI/UX</li>
            <li>iOS</li>
            <li>Android</li>
          </ul>
          <p>
            擔心毛小孩獨自溜出門迷路嗎？
            <br />
            透過 iPet 愛寵 GPS協尋器 APP，就可以不需要擔心查詢目前毛小孩的狀態！
          </p>
          <img src={ImgIpet01} alt='thumbnail01' />
          <Hyperlink
            text='View on App store'
            go='https://apps.apple.com/cn/app/%E6%84%9B%E5%AF%B5gps%E5%AF%B5%E7%89%A9%E8%BF%BD%E8%B9%A4%E5%99%A8/id914282231'
          />
          <Title title='Challenge' descript='挑戰' />
          <p>
            客戶基於某些原因，想藉此機會優化整個使用體驗，
            重新設計優化使用介面，進入APP可即時看到定位及列表。
          </p>
          <img src={ImgIpet02} alt='thumbnail02' />
          <Title title='App Design' descript='設計畫面' />
          <img src={ImgIpet03} alt='thumbnail03' />
          <img src={ImgIpet04} alt='thumbnail04' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default IPetScreen;
