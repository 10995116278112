import React from "react";
import IconHyperlink from "../../assets/images/ic-hyperlink.svg";

const Button = (props) => {
  return (
    <div className="btn-wrap">
      <a href={props.go} className="btn" target="_blank" rel="noreferrer">
        <img src={IconHyperlink} alt="hyperlink" />
        {props.text}
      </a>
    </div>
  );
};

export default Button;
