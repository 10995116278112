import React, { useState } from "react";
import { Link } from "react-router-dom";
const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    click
      ? (document.body.style.overflow = "auto")
      : (document.body.style.overflow = "hidden");
  };
  const closeMobileMenu = () => {
    setClick(false);
    document.body.style.overflow = "auto";
  };
  return (
    <div className="header">
      <div className="container">
        <h1>
          <Link to="/" className="logo">
            Home
          </Link>
        </h1>
        <nav className={click ? "nav active" : "nav"} onClick={closeMobileMenu}>
          <Link to="/">HOME</Link>
          <Link to="/about">ABOUT</Link>
          <Link to="/works">WORKS</Link>
          <Link to="/videos">VIDEOS</Link>
        </nav>
        <div
          className={click ? "btn-menu times" : "btn-menu"}
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Header;
