import React from "react";
const Footer = () => {
  return (
    <div className="footer">
      <p>Copyright © 2023 GiGi DESIGN</p>
    </div>
  );
};

export default Footer;
