import React from "react";
import { H3, H5 } from "../Typography";
import { Link } from "react-router-dom";

const WorkItem = (props) => {
  return (
    <div className="work-item" data-aos="fade-up">
      <Link to={props.go}>
        <img src={props.url} alt={props.alt} />
        <div className="work-info">
          <H3>{props.title}</H3>
          <H5>{props.descript}</H5>
        </div>
        <div className="label">{props.label}</div>
      </Link>
    </div>
  );
};

export default WorkItem;
