import React from 'react';
import Button from '../../components/Button';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgLifong01 from '../../assets/images/detail/lifong01.png';
import ImgLifong02 from '../../assets/images/detail/lifong02.png';
import ImgLifong03 from '../../assets/images/detail/lifong03.png';
import ImgLifong04 from '../../assets/images/detail/lifong04.png';
const LiFongScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='利丰支付' />
        <div className='detail-content'>
          <Title title='Wireframe' descript='草圖' />
          <img src={ImgLifong01} alt='thumbnail01' />
          <Title title='LOGO Vector' descript='向量繪製' />
          <img src={ImgLifong02} alt='thumbnail02' />
          <img src={ImgLifong03} alt='thumbnail03' />
          <Title title='Final' descript='最終定稿' />
          <p>
            支付最先聯想到的圖案就是check的圖像，
            再運用集中的幾何圖形，搭配專業安全感的配色，
            建立強而有力的支付品牌形象
          </p>
          <img src={ImgLifong04} alt='thumbnail04' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default LiFongScreen;
