import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgLife01 from '../../assets/images/detail/life01.png';
import ImgLife02 from '../../assets/images/detail/life02.png';
import ImgLife03 from '../../assets/images/detail/life03.png';

const LifeScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='17Life' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>UI/UX</li>
            <li>iOS</li>
            <li>Android</li>
            <li>WebDev</li>
          </ul>
          <p>
            17Life
            生活電商提供24H到貨，超多的團購商品每日超低折扣優惠，即買即用的餐旅住宿券等等。
          </p>
          <img src={ImgLife01} alt='thumbnail01' />
          <Hyperlink
            text='View on App store'
            go='https://apps.apple.com/tw/app/17life%E7%94%9F%E6%B4%BB%E9%9B%BB%E5%95%86/id543439591'
          />
          <Title title='Challenge' descript='挑戰' />
          <p>
            調整首頁、商品列表、商品內頁呈現，
            <br />
            優化使用者瀏覽商品體驗，
            新增個人主頁，整合與使用者相關的優惠與消費。
          </p>
          <img src={ImgLife02} alt='thumbnail02' />
          <Title title='Banner' descript='廣告圖' />
          <img src={ImgLife03} alt='thumbnail03' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default LifeScreen;
