import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgStancare01 from '../../assets/images/detail/stancare01.png';
import ImgStancare02 from '../../assets/images/detail/stancare02.png';
import ImgStancare03 from '../../assets/images/detail/stancare03.png';

const StancareScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='巨安長齡 “StanCare“' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>UI/UX</li>
            <li>iOS</li>
            <li>Android</li>
          </ul>
          <p>
            StanCare居服APP，
            <br />
            協助居家照顧服務員瀏覽過去服務紀錄，現在及未來之班表內容，
            並有到班/簽退打卡機制，個案服務項目瀏覽，服務時間調整等功能。
          </p>
          <img src={ImgStancare01} alt='thumbnail01' />
          <Hyperlink
            text='View on App store'
            go='https://apps.apple.com/tw/app/stancare/id1362988895'
          />
          <Title title='App Design' descript='設計畫面' />
          <p>依照ios和android系統的不同，優化使用者體驗設計。</p>
          <img src={ImgStancare02} alt='thumbnail02' />
          <img src={ImgStancare03} alt='thumbnail03' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default StancareScreen;
