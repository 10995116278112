import styled from 'styled-components';
//36px
export const H2 = styled.h2`
  font-size: 2.25rem;
`;
//18px
export const H3 = styled.h3`
  font-size: 1.125rem;
`;
//16px
export const H4 = styled.p`
  font-size: 1rem;
`;
//12px
export const H5 = styled.p`
  font-size: 0.75rem;
`;
