import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import DetailTitle from '../../components/DetailTitle';
import ImgFB from '../../assets/images/detail/fb01.png';

const FBScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='富邦產險 保全聯盟鏈' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JQuery</li>
            <li>RWD</li>
          </ul>
          <div className='btn-wrap'>
            <Hyperlink
              text='View at fubon.com/insurance'
              go='https://www.fubon.com/insurance/b2c/content/insurance_chain/index.html'
            />
          </div>
          <img src={ImgFB} alt='thumbnail' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default FBScreen;
