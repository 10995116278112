import React from "react";
import Button from "../../components/Button";
import Title from "../../components/Title";
import DetailTitle from "../../components/DetailTitle";
import ImgPxec01 from "../../assets/images/detail/pxec01-EDM.png";
import ImgPxec02 from "../../assets/images/detail/pxec02-ads.png";
import ImgPxec03 from "../../assets/images/detail/pxec03-dm.png";
import ImgPxec04 from "../../assets/images/detail/pxec04-web.png";
import ImgPxec05 from "../../assets/images/detail/pxec05-pxtv.png";
const PxecScreen = () => {
  return (
    <div className="container">
      <div className="detail-wrap row">
        <DetailTitle title="全聯線上購-隔日達" />
        <div className="detail-content">
          <ul className="skills">
            <li>Graphic Design</li>
            <li>Banner</li>
            <li>Event pages</li>
            <li>Ads</li>
          </ul>
          <p>
            參與全台最大連鎖超級市場，全聯福利中心的線上購物-隔日達服務的行銷製作，包括線上優惠活動相關視覺製作、廣告投放多媒體影片製作、海報及宣傳單平面設計輸出...等等。
          </p>
          <Title title="Banner" descript="行銷活動視覺圖" />
          <img src={ImgPxec01} alt="ImgPxmartEDM" />
          <Title title="Display Ads" descript="多平台廣告投放" />
          <img src={ImgPxec02} alt="ImgPxmartAds" />
          <Title title="Graphic Design" descript="店內活動陳設平面輸出" />
          <img src={ImgPxec03} alt="ImgPxmartDm" />
          <Title title="Event Pages" descript="行銷活動頁" />
          <p>
            運用引人注目的視覺第一時間抓住消費者，精選主打商品搭配促銷和折扣優惠，吸引消費者瀏覽與加入購物車購買，提升購物體驗。
          </p>
          <img src={ImgPxec04} alt="ImgPxmartWeb" />
          <Title title="TV Animation" descript="門市電視廣告動畫" />
          <img src={ImgPxec05} alt="ImgPxmartTV" />
        </div>
        <Button text="BACK" go="/works" />
      </div>
    </div>
  );
};
export default PxecScreen;
