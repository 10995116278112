import React from 'react';
import Button from '../../components/Button';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgCJPF01 from '../../assets/images/detail/cfjp01.png';
import ImgCJPF02 from '../../assets/images/detail/cfjp02.png';
import ImgCJPF03 from '../../assets/images/detail/cfjp03.png';
import ImgCJPF04 from '../../assets/images/detail/cfjp04.png';
import ImgCJPF05 from '../../assets/images/detail/cfjp05.png';

const CJPFScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='長江跑分' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>Branding</li>
            <li>UI/UX</li>
            <li>React Native</li>
          </ul>
          <img src={ImgCJPF01} alt='thumbnail01' />
          <p>
            從無到有完整的參與專案開發，包括品牌識別設計、APP 的 mockup 繪製、
            製作 Prototype 互動確認最小可行性產品、 React Native 製作設計規範，
            後期產品推廣的官網和下載頁的製作等等。
          </p>
          <Title title='Branging' descript='品牌識別' />
          <img src={ImgCJPF02} alt='thumbnail02' />
          <Title title='App Design' descript='UI/UX 設計' />
          <img src={ImgCJPF03} alt='thumbnail03' />
          <Title title='Web Design Dev' descript='網頁設計開發' />
          <img src={ImgCJPF04} alt='thumbnail04' />
          <img src={ImgCJPF05} alt='thumbnail05' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default CJPFScreen;
