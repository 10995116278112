import React from 'react';
import Button from '../../components/Button';
import DetailTitle from '../../components/DetailTitle';
import ImgIM from '../../assets/images/detail/im01.png';
const IMScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='IM 產品下載頁' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>React</li>
            <li>RWD</li>
          </ul>
          <img src={ImgIM} alt='thumbnail' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default IMScreen;
