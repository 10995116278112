import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgMayfull01 from '../../assets/images/detail/myfull01.png';
import ImgMayfull02 from '../../assets/images/detail/myfull02.png';
const MayfullScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='美福大飯店' />
        <div className='detail-content'>
          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JQuery</li>
            <li>RWD</li>
          </ul>
          <Hyperlink
            text='View at grandmayfull.com'
            go='https://www.grandmayfull.com'
          />
          <img src={ImgMayfull01} alt='thumbnail01' />
          <Title title='Challenge' descript='挑戰' />
          <p>
            網頁的視覺版面已完成，但只有為期2週的時間， 要完成整站的Responsive
            Web Design 切版開發。
          </p>
          <img src={ImgMayfull02} alt='thumbnail02' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default MayfullScreen;
