import React from 'react';
import { H3 } from '../Typography';

const Feature = props => {
  return (
    <div className='feature-item'>
      <div className='circle'>
        <img src={props.icon} alt={props.alt} />
      </div>
      <H3>{props.text}</H3>
    </div>
  );
};

export default Feature;
