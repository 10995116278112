import React from "react";
import Title from "../../components/Title";
import ImgHeadshot from "../../assets/images/headshot.png";

const AboutScreen = () => {
  return (
    <div className="container">
      <div className="row">
        <Title title="Profile" descript="自介" />
        <div className="about-wrap">
          <img src={ImgHeadshot} alt="headshot" />
          <div className="about-body">
            <h3>歐 珍貝&nbsp;&nbsp;&nbsp;GiGi Ou</h3>
            <ul>
              <li>設計經驗豐富，熟悉專案流程做事有效率。</li>
              <li>
                有參與創業團隊經驗，熟知 UI /UX流程，並具有完整 app
                或網站專案經驗。
              </li>
              <li>
                擁有很棒的人際溝通技巧，懂得如何與公司中的不同角色協調、合作。
              </li>
              <li>學習動力強烈，追求新知不落俗，讓開發更有效率，作品更好。</li>
            </ul>
          </div>
        </div>
        <div className="about-info">
          <div className="column">
            <div className="item">
              <h3>Personal traits</h3>
              <ul>
                <li>conscientious</li>
                <li>optimistic</li>
                <li>modest</li>
                <li>independent</li>
                <li>flexible</li>
                <li>communicative</li>
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="item">
              <h3>Skills</h3>
              <ul>
                <li>UI/UX</li>
                <li>Figma/Sketch/XD</li>
                <li>Photoshop/Illustrator</li>
                <li>HTML/CSS/JS</li>
                <li>React/React Native</li>
                <li>AE/Premiere/FCP</li>
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="item">
              <h3>Social</h3>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/ojbgigi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/ojbgigi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="item">
              <h3>Contact</h3>
              <ul>
                <li>LineID: ogigi</li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutScreen;
