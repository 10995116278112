import React from "react";
import Title from "../../components/Title";

const VedioScreen = () => {
  return (
    <div className="container">
      <div className="row">
        <Title title="Videos" descript="影片" />
        <div className="vbox">
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8MunpyIxJxw"
              title="慶中元電梯TV "
            ></iframe>
          </div>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CdxDRZIueo0"
              title="618 年中慶 YouTube Bumper"
            ></iframe>
          </div>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/LAacEFiIYAA"
              title="DISC主管篇"
            ></iframe>
          </div>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oZL6VqNwSME"
              title="仁寶i照護服務介紹"
            ></iframe>
          </div>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jusDzSN-rW8"
              title="圓夢講座影片"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VedioScreen;
