import React from 'react';
import Button from '../../components/Button';
import Title from '../../components/Title';
import DetailTitle from '../../components/DetailTitle';
import ImgGteng01 from '../../assets/images/detail/gteng01.png';
import ImgGteng02 from '../../assets/images/detail/gteng02.png';
import ImgGteng03 from '../../assets/images/detail/gteng03.png';

const GtengScreen = () => {
  return (
    <div className='container'>
      <div className='detail-wrap row'>
        <DetailTitle title='聚天股份有限公司' />
        <div className='detail-content'>
          <img src={ImgGteng01} alt='thumbnail01' />
          <img src={ImgGteng02} alt='thumbnail02' />
          <Title title='Branging' descript='品牌識別' />
          <img src={ImgGteng03} alt='thumbnail03' />
        </div>
        <Button text='BACK' go='/works' />
      </div>
    </div>
  );
};
export default GtengScreen;
